import {
  AccountClient,
  LinkClient,
  OrganizationClient,
  RFIsClient,
} from "../lib/API";

export default {
  install(app) {
    app.config.globalProperties.$api = {
      Account: new AccountClient(),
      Link: new LinkClient(),
      Organization: new OrganizationClient(),
      RFIs: new RFIsClient(),
    };
  },
};
