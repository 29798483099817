import {
  breakpointsVuetify,
  breakpointsTailwind,
  useBreakpoints as useBreakpointsVueUse,
} from "@vueuse/core";

export function useBreakpoints() {
  // NOTE: breakpointsTailwind uses the default Tailwind breakpoints, so we will need to change this
  // if we ever diverge from the defaults
  const breakpoints = useBreakpointsVueUse(breakpointsTailwind);

  const vuetifyBreakpoints = useBreakpointsVueUse(breakpointsVuetify);

  return {
    ...breakpoints,

    // These are temporary breakpoints meant to look similar to Vuetify plugin breakpoints to ease
    // migration efforts; ideally we'd move to unify around more "semantic" breakpoints, e.g.
    // "mobile" or "small screen", etc. Despite that these come from Vuetify v2, these should be
    // effectively the same for the few breakpoints we actually rely on in-app
    xsOnly: vuetifyBreakpoints.smallerOrEqual("xs"),
    smAndDown: breakpoints.smallerOrEqual("sm"),
    smAndUp: breakpoints.greaterOrEqual("sm"),
    mdAndDown: vuetifyBreakpoints.smallerOrEqual("md"),
    mdAndUp: vuetifyBreakpoints.greaterOrEqual("md"),
    lgAndUp: vuetifyBreakpoints.greaterOrEqual("lg"),
    lgAndDown: vuetifyBreakpoints.smallerOrEqual("lg"),

    // "Semantic" breakpoints
    mobile: breakpoints.smaller("md"),
    nonMobile: breakpoints.greaterOrEqual("md"),
  };
}
