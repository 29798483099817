<script setup lang="ts">
import { useFontsReady } from "@/lib/useFonts";
import { Ref } from "vue";

const fontsReady: Ref<boolean> = useFontsReady();

const props = defineProps<{
  left?: boolean;
  right?: boolean;
  small?: boolean;
  large?: boolean;
  outline?: boolean;
}>();

if (props.left && props.right) {
  console.warn("Either `left` or `right` should be used, not both");
}

if (props.small && props.large) {
  console.warn("Either `small` or `large` should be used, not both");
}

const emit = defineEmits<{
  (e: "click", event: MouseEvent): void;
}>();
</script>

<style scoped>
.icon-filled {
  /** Adjust these settings as needed
  Note that we are downloading specific font-weights for this to reduce bundle size
  so if you want to change them you'll need to do it here and in package.json */
  font-variation-settings: "FILL" 1;
}
.icon-outlined {
  font-variation-settings: "FILL" 0;
}
/** hide the icon until the app is loaded */
/** note that the width is set below in the template to keep from FOUC */
.material-symbols-rounded {
  opacity: 0;
  visibility: hidden;
}
.material-symbols-rounded.fonts-loaded {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s;
}
</style>

<template>
  <span
    class="material-symbols-rounded !tw-leading-none tw-text-current tw-inline-flex tw-items-center tw-justify-center tw-align-text-bottom"
    :class="{
      'icon-filled': !outline,
      'icon-outlined': outline,
      'tw-mr-2': left,
      'tw-ml-2': right,
      'tw-text-base': small,
      'tw-text-2xl': large,
      'tw-text-xl': !(small || large),
      'tw-w-[16px]': small,
      'tw-w-[36px]': large,
      'tw-w-[20px]': !(small || large),
      'fonts-loaded': fontsReady || false,
    }"
    @click="emit('click', $event)"
  >
    <slot />
  </span>
</template>
